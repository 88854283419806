import React, { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid, Icon, Statistic, Modal, Button, Image, Header, Form } from 'semantic-ui-react';
import { IContainerOverviewData } from './IContainerOverviewData';
import EliusBarChart from '../../components/charts/eliusbarchart';
import EliusLineChart from '../../components/charts/eliuslinechart';
import CircularProgressBar from '../../components/charts/circularprogressbar';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IContainerChartValue } from './IContainerChartValue';
import { IContainerImage } from './IContainerImagePrediction';
import ImageWithBoundingBoxes from '../../components/image/imageBoundingBox';
import ImageModalComponent from '../../components/image/imageModalComponent';
import RecipeForm from '../recipe/recipeform';
import { Recipe } from '../recipe/recipe';
import CadModelViewer from './CadModelViewer';
import { Greenhouse } from './models/Greenhouse';
import { fetchGreenhouseData, fetchGreenhouseImages } from '../../api/api';
import { ResponseGreenhouse } from './models/ResponseGreenhouse';
import InfoCard from '../../components/Infocard';
import { ImageData, ImagesResponse } from './models/ImageResponse';
import { fetchRecipe, updateRecipe } from '../../api/apirecipe';
import PlcModal from './PlcModal';
import { fetchControllableTags } from '../../api/apicontrol';
import { PLCTag } from './models/PLCTag';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export default function ContainerOverview() {

    const location = useLocation();
    const [data, setData] = useState<ResponseGreenhouse>();
    const [images, setImages] = useState<ImageData[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalHeader, setModalHeader] = useState("");
    const [modalData, setModalData] = useState<IContainerChartValue>();
    const [modalCurrentValue, setModalCurrentValue] = useState(24);
    const [modalValueStep, setModalValueStep] = useState(1);
    const [modalMinValue, setModalMinValue] = useState(14);
    const [modalMaxValue, setModalMaxValue] = useState(34);
    const [lightsOn, setLightsOn] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isRecipeModalOpen, setRecipeModalOpen] = useState(false);
    const [recipeData, setRecipeData] = useState<Recipe | undefined>(undefined); // Store the single recipe
    const [plcTags, setPLCTags] = useState<PLCTag[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [plcModalOpen, setPLCModalOpen] = useState(false);

    // Display data
    const [currentTemperature, setTemperature] = useState(22.4);
    const [targetTemperature, setTargetTemperature] = useState(24.4);
    const [temperatureData, setTemperatureData] = useState(Array<number>());
    const [currentHumidity, setHumidity] = useState(59);
    const [targetHumidity, setTargetHumidity] = useState(55);
    const [humidityData, setHumidityData] = useState(Array<number>());
    const [currentCo2, setCurrentCo2] = useState(565);
    const [targetCo2, setTargetCurrentCo2] = useState(700);
    const [co2Data, setCo2Data] = useState(Array<number>());
    const [timestamps, setTimestamps] = useState(Array<string>());

    // Displaying AI-images
    const [dataLoading, setDataLoading] = useState(false);
    const [imageDataList, setImageData] = useState<IContainerImage[]>();
    const [selectedImage, setSelectedImage] = useState<IContainerImage>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imageNumber, setImageNumber] = useState<number>(-1);
    const [highlightedTags, setHighlightedTags] = useState([]);
    const greenhouseId = location.state?.greenhouseId as string;

    // Function to open the recipe modal
    const openRecipeModal = async () => {

        // Get data
        let data = await fetchRecipe(greenhouseId)
        setRecipeData(data);

        setRecipeModalOpen(true);
    }

    // Function to open the control modal
    const openControlModal = async () => {

        // Get data
        let data = await fetchControllableTags(greenhouseId)
        if (data !== undefined) {
            setPLCTags(data);
        }

        setPLCModalOpen(true);
    }

    useEffect(() => {

        const loadData = async () => {
            setDataLoading(true);
            try {
                const response = await fetchGreenhouseData(greenhouseId);
                setData(response);

                let dataSetLabels = [];

                if (response?.timeseries !== undefined) {
                    for (let i = 0; i < response?.timeseries.length; i++) {
                        dataSetLabels.push(new Date(response.timeseries[i].timestamp).toISOString().split(':')[0].replace("T", " ") + ":" + new Date(response.timeseries[i].timestamp).toISOString().split(':')[1]);
                    }
                    setTimestamps(dataSetLabels);
                }

            } catch (error) {
                console.error("Failed to load data:", error);
            } finally {
                setDataLoading(false);
            }
        };

        loadData();
    }, [])

    useEffect(() => {
        setDataLoading(true);

        fetch(`${apiUrl}ImageAnalysis`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        })
            .then(response => response.json())
            .then(res => setImageData(res))
            .catch(err => console.log(err));

        setDataLoading(false);

    }, [])


    const imageClicked = (imageNumber: number) => {
        let image = imageDataList?.at(imageNumber);
        setSelectedImage(image);
        setImageNumber(imageNumber);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setHighlightedTags([]);
    };

    const goToNextModal = () => {
        if (imageNumber < (imageDataList?.length ?? 0) - 1) {
            setImageNumber(imageNumber + 1);
            setSelectedImage(imageDataList?.at(imageNumber + 1));
        }
    };

    const goToPreviousModal = () => {
        if (imageNumber > 0) {
            setImageNumber(imageNumber - 1);
            setSelectedImage(imageDataList?.at(imageNumber - 1));
        }
    };

    // Handlers for navigation
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Fetch images
    const fetchImages = async () => {

        const greenhouseId = location.state?.greenhouseId as string;

        try {
            const data = await fetchGreenhouseImages(greenhouseId);
            if (data !== undefined) {
                // Sort images by timestamp (newest first) and update state
                const sortedImages = data.images.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
                setImages(sortedImages);
                setModalOpen(true);
            } else {
                alert("No images available")
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    // Function to format timestamp
    const formatTimestamp = (timestamp: string) => {
        const date = new Date(timestamp);
        return date.toISOString().slice(0, 16).replace("T", " "); // Removes seconds and milliseconds
    };

    const handleSaveRecipe = async (recipe: Recipe) => {
        // Save the new data
        await updateRecipe(greenhouseId, recipe);

        setRecipeModalOpen(false);
    };

    const handleDirectControl = async (tagName: string, value: number) => {
        console.log(tagName + ": " + value);
    }

    return (
        <Grid padded>
            <Grid.Row>
                <div style={{ marginLeft: 15 }}>
                    <Button primary onClick={() => openRecipeModal()}>Recipe</Button>
                    <Button onClick={() => fetchImages()} style={{ marginLeft: 20 }} primary>Images</Button>
                    {/* <Button onClick={() => openControlModal()} style={{ marginLeft: 20 }} primary>Control</Button> */}
                    <Modal open={modalOpen} onClose={() => setModalOpen(false)} closeIcon>
                        <Header>Image Gallery</Header>
                        <Modal.Content style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {images.length > 0 && (
                                <div style={{ textAlign: 'center' }}>
                                    {/* Display Current Image Position */}
                                    <p style={{ fontSize: '1rem', color: 'gray', marginBottom: '10px' }}>
                                        {`Image ${currentIndex + 1} of ${images.length}`}
                                    </p>

                                    <Header as="h4">{formatTimestamp(images[currentIndex].timestamp)}</Header>
                                    <Image src={`data:image/jpeg;base64,${images[currentIndex].base64Image}`} size="medium" centered />
                                </div>
                            )}

                            {/* Previous Button */}
                            <Button
                                icon
                                style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
                                onClick={handlePrevious}
                            >
                                <Icon name="chevron left" />
                            </Button>

                            {/* Next Button */}
                            <Button
                                icon
                                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1 }}
                                onClick={handleNext}
                            >
                                <Icon name="chevron right" />
                            </Button>
                        </Modal.Content>
                    </Modal>
                    <Modal open={isRecipeModalOpen} onClose={() => setRecipeModalOpen(false)} closeIcon>
                        <Header>Recipe</Header>
                        <Modal.Content>
                            <RecipeForm initialData={recipeData} onSave={handleSaveRecipe}></RecipeForm>
                        </Modal.Content>
                    </Modal>
                    <PlcModal
                        open={plcModalOpen}
                        onClose={() => setPLCModalOpen(false)}
                        onTagValueChange={(e, v) => handleDirectControl(e, v)}
                        tags={plcTags}
                    />
                </div>
            </Grid.Row>
            <Grid.Row columns={4}>
                <Grid.Column>
                    <InfoCard
                        header={data === undefined ? 0 : data.greenhouse.electricityUsedTodayKwh}
                        meta="Electricity (Kwh)"
                        description={`${data === undefined ? "" : data.greenhouse.electricityUsedTodayKwh}%`}
                        icon="bolt"
                        trend={10} // Example trend value; replace with actual data if available
                    />
                </Grid.Column>
                <Grid.Column>
                    <InfoCard
                        header={data === undefined ? 0 : data.greenhouse.waterUsedTodayLitres}
                        meta="Water (litres)"
                        description={`${data === undefined ? "" : data.greenhouse.waterUsedTodayLitres}%`}
                        icon="tint"
                        trend={2} // Example trend value; replace with actual data if available
                    />
                </Grid.Column>
                <Grid.Column>
                    <InfoCard
                        header={data === undefined ? 0 : data.greenhouse.issues.length}
                        meta="Recommendations"
                        description=""
                        icon="ambulance"
                    />
                </Grid.Column>
                <Grid.Column>
                    <InfoCard
                        header={data === undefined ? 0 : data.greenhouse.issues.length}
                        meta="Issues"
                        description=""
                        icon="exclamation triangle"
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className="grid-row-compact">
                <Grid.Column width={6} className="grid-column-compact">
                    <div className={'NumberCard'}>
                        <Statistic size={'small'} color={currentTemperature - 1.0 > targetTemperature ? 'red' : currentTemperature + 1.0 < targetTemperature ? 'red' : 'green'} className='SemanticStatisticCard'>
                            <Statistic.Label>Temperature</Statistic.Label>
                            <Statistic.Value>{currentTemperature}</Statistic.Value>
                            <Statistic.Label>{"Target: " + targetTemperature}</Statistic.Label>
                        </Statistic>
                    </div>
                </Grid.Column>
                <Grid.Column width={10} className="grid-column-compact">
                    <EliusLineChart
                        data={temperatureData}
                        labels={timestamps}
                        maxValue={35}
                        minValue={15}
                        title={"Temperature"}
                        showTitle={false}
                        maxHeight={150}
                    />
                </Grid.Column>

            </Grid.Row>
            <Grid.Row className="grid-row-compact">

                <Grid.Column width={6} className="grid-column-compact">
                    <div className={'NumberCard'}>
                        <Statistic size={'small'} color={currentHumidity - 5 > targetHumidity ? 'red' : currentHumidity + 5 < targetHumidity ? 'red' : 'green'} className='SemanticStatisticCard'>
                            <Statistic.Label>Humidity</Statistic.Label>
                            <Statistic.Value>{currentHumidity}</Statistic.Value>
                            <Statistic.Label>{"Target: " + targetHumidity}</Statistic.Label>
                        </Statistic>
                    </div>
                </Grid.Column>

                <Grid.Column width={10} className="grid-column-compact">
                    <EliusLineChart
                        data={humidityData}
                        labels={timestamps}
                        maxValue={100}
                        minValue={0}
                        title={"Humidity"}
                        showTitle={false}
                        maxHeight={150}
                    />
                </Grid.Column>

            </Grid.Row>
            <Grid.Row className="grid-row-compact">
                <Grid.Column width={6} className="grid-column-compact">
                    <div className={'NumberCard'}>
                        <Statistic size={'small'} color={currentCo2 - 50 > targetCo2 ? 'red' : currentCo2 + 50 < targetCo2 ? 'red' : 'green'} className='SemanticStatisticCard'>
                            <Statistic.Label>Co2</Statistic.Label>
                            <Statistic.Value>{currentCo2}</Statistic.Value>
                            <Statistic.Label>{"Target: " + targetCo2}</Statistic.Label>
                        </Statistic>
                    </div>
                </Grid.Column>

                <Grid.Column width={10} className="grid-column-compact">
                    <EliusLineChart
                        data={co2Data}
                        labels={timestamps}
                        maxValue={1600}
                        minValue={400}
                        title={"Co2"}
                        showTitle={false}
                        maxHeight={150}
                    />
                </Grid.Column>

            </Grid.Row>
        </Grid>
    );
}