import React, { useState, ChangeEvent } from "react";
import { Modal, Button, Form, Checkbox } from "semantic-ui-react";
import { PLCTag } from "./models/PLCTag";

interface PlcModalProps {
    open: boolean;
    onClose: () => void;
    /** Called whenever any tag value changes. */
    onTagValueChange: (tagName: string, tagValue: number) => void;
    /** The current list of tags to display/control. */
    tags: PLCTag[];
}

const PlcModal: React.FC<PlcModalProps> = ({
    open,
    onClose,
    onTagValueChange,
    tags,
}) => {
    // Keep a local copy of tags so we can display them
    const [localTags, setLocalTags] = useState<PLCTag[]>(() => [...tags]);

    // If parent updates `tags` from the outside, sync again
    React.useEffect(() => {
        setLocalTags([...tags]);
    }, [tags]);

    // Handle a numeric (int/double) change
    const handleNumericChange = (index: number, newValue: number) => {
        const updated = [...localTags];
        updated[index].latestValue = newValue;
        setLocalTags(updated);
        // Notify parent
        onTagValueChange(updated[index].tagName, newValue);
    };

    // Handle a boolean (toggle) change
    const handleToggle = (index: number, checked: boolean) => {
        const updated = [...localTags];
        // Convert true => 1, false => 0
        updated[index].latestValue = checked ? 1 : 0;
        setLocalTags(updated);
        // Notify parent
        const numericValue = checked ? 1 : 0;
        onTagValueChange(updated[index].tagName, numericValue);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Direct Control</Modal.Header>
            <Modal.Content>
                <Form>
                    {localTags.map((tag, idx) => (
                        <Form.Field key={tag.name + idx}>
                            {/* Tag name + description (read-only) */}
                            <label>{tag.description}</label>
                            {/* If not remote-controllable, show a note */}
                            {!tag.isRemoteControllable ? (
                                <p style={{ color: "gray" }}>Not remote controllable</p>
                            ) : (
                                <div style={{maxWidth: 300}}>
                                    {tag.plcTagType === "1" || tag.plcTagType === "boolValue" ? (
                                        // Boolean => Toggle Switch
                                        <Checkbox
                                            toggle
                                            checked={!!tag.latestValue} // 1 => true, 0 => false
                                            onChange={(_, data) => handleToggle(idx, !!data.checked)}
                                        />
                                    ) : (
                                        // Numeric => Number Input
                                        <Form.Input
                                            type="number"
                                            value={tag.latestValue}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                handleNumericChange(idx, parseFloat(e.target.value))
                                            }
                                            min={tag.minValue}
                                            max={tag.maxValue}
                                            step={0.5}
                                        />
                                    )}
                                </div>
                            )}
                            <hr />
                        </Form.Field>
                    ))}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {/* Only a Close button now */}
                <Button onClick={onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PlcModal;