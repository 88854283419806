import React, { useEffect, useState } from 'react'
import { Icon, Menu, MenuHeader } from 'semantic-ui-react'
import { useNavigate } from "react-router-dom";

export default function Sidemenu() {
    const [activeItem, setActiveItem] = useState("overview");
    const navigate = useNavigate();

    const handleItemClick = (name: string) => {
        setActiveItem(name);
        navigate(`/${name}`);
    }

    return (
        <div className='Sidebar'>
            <Menu fluid vertical secondary>
                <Menu.Item
                    name='overview'
                    active={activeItem === 'overview'}
                    className={activeItem === 'overview' ? "MenuItemTextActive" : "MenuItemText"}
                    onClick={() => handleItemClick('overview')}
                >
                    <Icon.Group size='large' className={activeItem === 'overview' ? "MenuItemIconActive" : "MenuItemIcon"}>
                        <Icon name='globe' />
                    </Icon.Group>
                    <span>Overview</span>
                </Menu.Item>
                {/* <Menu.Item
                    name='Recipes'
                    active={activeItem === 'recipes'}
                    className={activeItem === 'recipes' ? "MenuItemTextActive" : "MenuItemText"}
                    onClick={() => handleItemClick('recipes')}
                >
                    <Icon.Group size='large' className={activeItem === 'recipes' ? "MenuItemIconActive" : "MenuItemIcon"}>
                        <Icon name='clipboard outline' />
                    </Icon.Group>
                    <span>Recipes</span>
                </Menu.Item>

                <Menu.Item
                    name='contact'
                    active={activeItem === 'contact'}
                    disabled={true}
                    onClick={() => handleItemClick('contact')}
                >
                    <Icon.Group size='large' className={activeItem === 'contact' ? "MenuItemIconActive" : "MenuItemIcon"}>
                        <Icon name='mail' />
                    </Icon.Group>
                    <span className={activeItem === 'email' ? "MenuItemTextActive" : "MenuItemText"}>Contact support</span>
                </Menu.Item>

                <Menu.Item
                    name='faq'
                    active={activeItem === 'faq'}
                    disabled={true}
                    onClick={() => handleItemClick('faq')}
                >
                    <Icon.Group size='large' className={activeItem === 'faq' ? "MenuItemIconActive" : "MenuItemIcon"}>
                        <Icon name='info' />
                    </Icon.Group>
                    <span className={activeItem === 'faq' ? "MenuItemTextActive" : "MenuItemText"}>FAQ</span>
                </Menu.Item> */}
            </Menu >
        </div>
    )
}