import React, { useState, useEffect } from 'react';
import { Button, Form, Message, Input } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import CryptoJS from "crypto-js";
import { updatePassword } from '../../api/api';

const Profile: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState(''); // This is the current password
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // Get the username from the token
        const token = Cookies.get('auth_token');
        if (token) {
            try {
                const decodedToken: { sub: string } = jwtDecode(token);
                setUsername(decodedToken.sub);
            } catch (error) {
                console.error('Failed to decode token', error);
            }
        }
    }, []);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (newPassword !== confirmPassword) {
            setErrorMessage("New passwords don't match.");
            return;
        }

        // Call the login function
        const result = await updatePassword(username, password, newPassword);

        if (result?.success === true) {
            setSuccessMessage(result?.responseText);
        } else if (result?.success === false) {
            setErrorMessage(result?.responseText);
        }
    };

    return (
        <div style={{ maxWidth: '400px', margin: '0 auto', paddingTop: '100px' }}>
            <h2>Update Password</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <label>Current Password</label>
                    <Input
                        type="password"
                        placeholder="Enter your current password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>New Password</label>
                    <Input
                        type="password"
                        placeholder="Enter a new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Confirm New Password</label>
                    <Input
                        type="password"
                        placeholder="Confirm your new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Form.Field>
                {errorMessage && <Message negative>{errorMessage}</Message>}
                {successMessage && <Message positive>{successMessage}</Message>}
                <Button type="submit" color="blue">
                    Update Password
                </Button>
            </Form>
        </div>
    );
};

export default Profile;
