import Cookies from "js-cookie";
import { ResponseGreenhouse } from "../pages/overview/models/ResponseGreenhouse";
import { ResponseGreenhouseOverview } from "../pages/overview/models/ResponseOverviewGreenhouse";
import { ImagesResponse } from "../pages/overview/models/ImageResponse";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js";
import { LoginResponse } from "./LoginResponse";
import { UpdatePasswordResponse } from "./UpdatePasswordResponse";

const apiUrl = process.env.REACT_APP_API_URL;
const apiCode = process.env.REACT_APP_API_CODE;

export async function fetchGreenhouseData(greenhouseId: string): Promise<ResponseGreenhouse | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/greenhouse?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ResponseGreenhouse = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function fetchGreenhouseOverview(): Promise<ResponseGreenhouseOverview | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/greenhouse?code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ResponseGreenhouseOverview = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse overview data:", error);
        return undefined;
    }
}

export async function fetchGreenhouseImages(greenhouseId: string): Promise<ImagesResponse | undefined> {
    try {
        // Retrieve the token from cookies
        const token = Cookies.get('auth_token');

        const response = await fetch(`${apiUrl}/api/image?greenhouseId=${greenhouseId}&code=${apiCode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data: ImagesResponse = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch greenhouse data:", error);
        return undefined;
    }
}

export async function login(username: string, password: string): Promise<LoginResponse | undefined> {
    try {
        // Hash the password (assuming `CryptoJS` is used for hashing)
        const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);

        // Make the API call
        const response = await fetch(`${apiUrl}/api/login?code=${apiCode}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password: hashedPassword }),
        });

        // Check if the response is ok (status 200)
        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        // Parse the JSON response
        const data = await response.json();

        // Extract the token from the response
        const { token } = data;

        // Decode the JWT token to extract the expiration time
        const decodedToken = jwtDecode(token);

        if (decodedToken.exp !== undefined) {
            const tokenExpiration = decodedToken.exp; // exp is in seconds since epoch

            // Calculate the expiration date for the cookie based on the JWT exp claim
            const expires = new Date(tokenExpiration * 1000); // Convert from seconds to milliseconds

            // Set the token cookie using the expiration from the JWT
            Cookies.set('auth_token', token, { expires: expires, secure: true, sameSite: 'Strict' });
        }

        return { token, userNumber: data.userNumber, loginSuccess: true }; // Adjust this as per your API response
    } catch (error) {
        console.error("Login failed:", error);
        return undefined;
    }
}

export async function updatePassword(username: string, password: string, newPassword: string): Promise<UpdatePasswordResponse | undefined> {
    // Hash the password with SHA-256
    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    const hashedNewPassword = CryptoJS.SHA256(newPassword).toString(CryptoJS.enc.Hex);

    try {
        const response = await fetch(`${apiUrl}/api/login&code=${apiCode}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('auth_token')}`, // Include the auth token
            },
            body: JSON.stringify({
                username: username,        // Username from the decoded token
                password: hashedPassword,       // Current password
                newPassword: hashedNewPassword,   // New password
            }),
        });

        if (response.ok) {
            return { responseText: 'Password updated successfully.', success: true };
        } else {
            const errorData = await response.json();
            return { responseText: 'Failed to update password.', success: false };
        }
    } catch (error) {
        return { responseText: 'An error occurred while updating the password.', success: false };
    }
}