import React, { useState, useEffect, useMemo } from 'react'
import { Statistic, Search, Grid, Table } from 'semantic-ui-react'
import { AzureMap, AzureMapsProvider, IAzureMapOptions, AuthenticationType, IAzureMapHtmlMarker, AzureMapFeature, IAzureMapFeature, AzureMapDataSourceProvider, IAzureMapLayerType, AzureMapHtmlMarker, IAzureMapHtmlMarkerEvent, IAzureDataSourceChildren, AzureSetCameraOptions } from 'react-azure-maps'
import '../../styles/OverviewStyles.css';
import atlas, { HtmlMarkerOptions, CameraOptions, data } from 'azure-maps-control';
import { NavigateOptions, useNavigate } from "react-router-dom";
import { IContainerOverviewData } from './IContainerOverviewData';
import { ResponseGreenhouse } from './models/ResponseGreenhouse';
import { fetchGreenhouseData, fetchGreenhouseOverview } from '../../api/api';
import { ResponseGreenhouseOverview } from './models/ResponseOverviewGreenhouse';
import { Greenhouse } from './models/Greenhouse';
import InfoCard from '../../components/Infocard';

const mapOption: IAzureMapOptions = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: 'yFVQCxrNK0tOMgXPsCoiMC738WaSh3Y4dswDnO7aKrA' // Your subscription key
    }
}

interface IOverviewData {
    plantsToday: number
    plantsTomorrow: number
    kgsProducedToday: number
    kgsProducedTomorrow: number
    dailyProduceTarget: number
    avgCo2KgPerPlant: number
    avgTargetCo2PerKg: number
    containerIssues: number
    containers: Array<IContainerOverviewData>
}

function azureHtmlMapMarkerOptions(id: string, longitude: number, latitude: number): HtmlMarkerOptions {
    let coordinates = [latitude, longitude];
    return {
        position: coordinates,
        text: id,
        title: 'Title'
    };
}

export default function Overview() {
    const [dataLoading, setDataLoading] = useState(false);
    const [overviewData, setOverviewData] = useState<ResponseGreenhouseOverview>();
    const [cameraOptions, setCameraOptions] = useState<AzureSetCameraOptions>();
    const [totalKwhUsed, setTotalKwhUsed] = useState(0);
    const [totalWaterUsed, setTotalWaterUsed] = useState(0);
    const [totalIssues, setTotalIssues] = useState(0);
    const [totalRecommendations, setTotalRecommendations] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const loadData = async () => {
            setDataLoading(true);
            try {
                const response = await fetchGreenhouseOverview();
                setOverviewData(response);

                if (response !== undefined) {
                    const totalKwh = response.greenhouses.reduce((sum, gh) => sum + gh.electricityUsedTodayKwh, 0);
                    const totalWater = response.greenhouses.reduce((sum, gh) => sum + gh.waterUsedTodayLitres, 0);
                    const totalIssueCount = response.greenhouses.reduce((sum, gh) => sum + gh.issues.length, 0);

                    setTotalKwhUsed(totalKwh);
                    setTotalWaterUsed(totalWater);
                    setTotalIssues(totalIssueCount);
                }

            } catch (error) {
                console.error("Failed to load data:", error);
            } finally {
                setDataLoading(false);
            }
        };

        // Call the async function
        loadData();
    }, [])

    useEffect(() => {
        if (overviewData !== undefined) {

            let longitude = overviewData.greenhouses.map(greenhouse => greenhouse.longitude).reduce((x, y) => x + y) / overviewData.greenhouses.length;
            let latitude = overviewData.greenhouses.map(greenhouse => greenhouse.latitude).reduce((x, y) => x + y) / overviewData.greenhouses.length;
            let centerPosition = new data.Position(latitude, longitude);

            const options = {} as CameraOptions;
            options.center = centerPosition;
            options.zoom = 7;
            setCameraOptions(options);
        }
    }, [overviewData])

    const renderHTMLPoint = (greenhouse: Greenhouse): any => {
        return (
            <AzureMapHtmlMarker
                key={greenhouse.id}
                markerContent={<div className={greenhouse.issues.length === 0 ? "pulseIcon" : "pulseIconError"}></div>}
                options={{ ...azureHtmlMapMarkerOptions(greenhouse.id, greenhouse.longitude, greenhouse.latitude) } as any}
                events={eventToMarker}
            />
        );
    }

    const containerMapMarkerClick = (e: any) => {
        let greenhouseId = e.target.options.text;
        let greenhouse = overviewData?.greenhouses.find(obj => obj.id === greenhouseId);

        let options = {} as NavigateOptions;
        options.state = { greenthouseId: greenhouseId };
        navigate(`/overview/${greenhouseId}`, options);
    };

    const containerListClick = (greenhouseId: string) => {
        let options = {} as NavigateOptions;
        options.state = { greenhouseId: greenhouseId };
        navigate(`/overview/${greenhouseId}`, options);
    };

    const eventToMarker: Array<IAzureMapHtmlMarkerEvent> = [{ eventName: 'click', callback: containerMapMarkerClick }];

    const memoizedHtmlMarkerRender: IAzureDataSourceChildren = useMemo(
        (): any => overviewData?.greenhouses.map((greenhouse) => renderHTMLPoint(greenhouse)),
        [overviewData?.greenhouses],
    );

    const greenhouseKwhUsage = (): number => {
        let kwhUsage = 0;

        if (overviewData !== undefined && overviewData.greenhouses.length > 0) {
            for (let index = 0; index < overviewData.greenhouses.length; index++) {
                kwhUsage += overviewData.greenhouses[index].electricityUsedTodayKwh;
            }
        }

        return kwhUsage;
    };

    const greenhouseWaterUsage = (): number => {
        let waterUsage = 0;

        if (overviewData !== undefined && overviewData.greenhouses.length > 0) {
            for (let index = 0; index < overviewData.greenhouses.length; index++) {
                waterUsage += overviewData.greenhouses[index].waterUsedTodayLitres;
            }
        }

        return waterUsage;
    };

    const greenhouseIssues = (): number => {
        let issues = 0;

        if (overviewData !== undefined && overviewData.greenhouses.length > 0) {
            for (let index = 0; index < overviewData.greenhouses.length; index++) {
                issues += overviewData.greenhouses[index].issues.length;
            }
        }

        return issues;
    };

    const greenhouseRecommendations = (): number => {
        let recommendations = 0;

        return recommendations;
    };

    return (
        <Grid>
            {/* <Grid.Row columns={4}>
                <Grid.Column>
                    <InfoCard
                        header={totalKwhUsed}
                        meta="Electricity (Kwh)"
                        description={`${totalKwhUsed}%`}
                        icon="bolt"
                        trend={10} // Example trend value; replace with actual data if available
                    />
                </Grid.Column>
                <Grid.Column>
                    <InfoCard
                        header={totalWaterUsed}
                        meta="Water (litres)"
                        description={`${totalWaterUsed}%`}
                        icon="tint"
                        trend={2} // Example trend value; replace with actual data if available
                    />
                </Grid.Column>
                <Grid.Column>
                    <InfoCard
                        header={totalIssues}
                        meta="Issues"
                        description=""
                        icon="ambulance"
                    />
                </Grid.Column>
                <Grid.Column>
                    <InfoCard
                        header={totalRecommendations}
                        meta="Recommendations"
                        description=""
                        icon="exclamation triangle"
                    />
                </Grid.Column>
            </Grid.Row> */}
            <Grid.Row>
                <Grid.Column width={6}>
                    <div>
                        {/* <Search
                            loading={searchLoading}
                            placeholder='Search...'
                            onSearchChange={handleSearchChange}
                            value={searchValue}
                        /> */}
                        <Table color='blue' key='blue' selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Container</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    overviewData?.greenhouses?.map(greenhouse => (
                                        <Table.Row className='TableRowClickable'
                                            onClick={() => {
                                                containerListClick(greenhouse.id);
                                            }}>
                                            <Table.Cell>{greenhouse.name}</Table.Cell>
                                            <Table.Cell className={greenhouse.isOnline ? 'ContainerPerformanceGood' : 'ContainerPerformanceBad'}>{greenhouse.isOnline ? "Online" : "Offline"}</Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </Grid.Column>
                <Grid.Column width={9}>
                    <AzureMapsProvider>
                        <div style={{ height: '600px', width: '100%' }}>
                            <AzureMap options={mapOption} cameraOptions={cameraOptions}>
                                <AzureMapDataSourceProvider id="MultiplePoint AzureMapDataSourceProvider">
                                    {memoizedHtmlMarkerRender}
                                </AzureMapDataSourceProvider>
                            </AzureMap>
                        </div>
                    </AzureMapsProvider>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

